import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { ProductDetail } from '~anyx/shared/graphql';
import { SkeletonLoader, Image, Card } from '~anyx/shared/ui';

import { RatingStar } from '../product-review';

interface ProductOverviewCardProps {
  channelSelector?: React.ReactNode;
  product?: Pick<ProductDetail, 'id' | 'name' | 'media' | 'description'>;
  averageRating?: number;
  totalRatings?: number;
  productLoading?: boolean;
  ratingLoading?: boolean;
  className?: string;
}

export const ProductOverviewCard = ({
  channelSelector,
  product,
  averageRating,
  totalRatings,
  productLoading,
  ratingLoading,
  className,
}: ProductOverviewCardProps) => {
  const { t } = useTranslation('shared');

  return (
    <Card withPadding={false} className={classNames('py-2 pl-2 pr-4', className)}>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 flex items-start md:col-span-1">
          <SkeletonLoader loading={productLoading} className="h-14">
            <Image
              alt={t('shared.action.image', {
                ns: 'shared',
                entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
              })}
              className="mr-2 h-14 w-14"
              noImageClassNameWrapper="mr-2 h-14 w-14"
              src={product?.media[0] || ''}
            />
            <div className="flex min-w-0 flex-col">
              <p className="truncate text-sm font-semibold">{product?.name}</p>
              <div className="flex items-center gap-2">
                <SkeletonLoader loading={ratingLoading} className="h-3.5 w-40">
                  <RatingStar averageRating={averageRating} size="sm" />
                  <p className="text-xs">
                    {`${t('report.page.product.outOfFive', {
                      ns: 'report',
                      amount: averageRating,
                      compact: true,
                    })} (${totalRatings})`}
                  </p>
                </SkeletonLoader>
              </div>
            </div>
          </SkeletonLoader>
        </div>

        {channelSelector && (
          <div className="col-span-2 flex items-center gap-2 md:col-span-1 md:justify-end">
            {t('report.page.product.for', { ns: 'report' })}
            {channelSelector}
          </div>
        )}
      </div>
    </Card>
  );
};
