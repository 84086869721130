import { useState } from 'react';

import { useStoresChannels } from '~anyx/feature/marketplace';
import { SaleChannelType } from '~anyx/shared/graphql';
import { useDeepComparisonEffect } from '~anyx/shared/utils';

export const useProductChannels = ({ storeId }: { storeId: string }) => {
  const { getStoresChannelsTypes, loading } = useStoresChannels();
  const [channels, setChannels] = useState<SaleChannelType[]>([]);

  useDeepComparisonEffect(async () => {
    setChannels(
      await getStoresChannelsTypes({
        storeIds: [storeId],
      })
    );
  }, [storeId]);

  return {
    channels,
    loading,
  };
};
