import { forwardRef, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { MultiSelect, MultiSelectProps } from '@any-ui-react/core';

import { CloseIcon } from '~anyx/shared/icons';

import { useProductTagsInputQuery } from './graphql';

type ProductTagInputProps = {
  masterDataStoreId: string;
  masterDataAccountId?: string;
  onChange: (tags: string[]) => void;
  value: string[];
} & Omit<MultiSelectProps, 'data' | 'value' | 'onChange'>;

interface MantineValueComponentProps {
  value: string;
  onRemove: () => void;
}

export const ProductTagInput = forwardRef<HTMLInputElement, ProductTagInputProps>(
  ({ masterDataAccountId, masterDataStoreId, onChange, value, ...rest }, ref) => {
    const { t } = useTranslation();
    const tagContainerRef = useRef<HTMLDivElement>(null);
    const [newOption, setNewOption] = useState<string[]>([]);
    const { data } = useProductTagsInputQuery({
      variables: {
        filter: {
          ...(masterDataAccountId && { masterDataAccountId }),
          ...(masterDataStoreId && { masterDataStoreIds: [masterDataStoreId] }),
        },
      },
    });

    const options = useMemo(() => {
      return [...(data?.tagsV2 || []), ...newOption].map((tag) => ({ label: tag, value: tag }));
    }, [data, newOption]);

    const ValueComponent = ({ value, onRemove }: MantineValueComponentProps) => {
      if (!tagContainerRef.current) {
        return null;
      }

      return ReactDOM.createPortal(
        <div className="bg-gray-2 text-2xs user-select-none inline-flex cursor-default items-center rounded p-1 pl-2">
          {value}
          <button
            type="button"
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={onRemove}
          >
            <CloseIcon type="nutton" className="ml-1 h-3 w-3 cursor-pointer" />
          </button>
        </div>,
        tagContainerRef.current
      );
    };

    return (
      <>
        <MultiSelect
          ref={ref}
          {...rest}
          data={options}
          value={value}
          getCreateLabel={(query) => `+ Create ${query}`}
          onCreate={(query) => {
            setNewOption((prevOptions) => [...prevOptions, query]);
            return { value: query, label: query };
          }}
          withinPortal
          searchable
          creatable
          onChange={onChange}
          valueComponent={ValueComponent}
          placeholder={t('marketplace.page.product.edit.card.tags.placeholder', {
            ns: 'marketplace',
          })}
        />
        <div ref={tagContainerRef} className="flex flex-wrap gap-2 pt-2" />
      </>
    );
  }
);
