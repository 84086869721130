import { useTranslation } from 'react-i18next';

import { TablePagination } from '@any-ui-react/core';

import { SkeletonLoader } from '~anyx/shared/ui';
import { DateUtils, NumberUtils } from '~anyx/shared/utils';

import { RatingComment } from '../rating-comment';

import { useGradientScroll } from './hooks';

interface CommentListProps {
  reviews: readonly {
    comment: string;
    date: string;
    rating: string;
  }[];
  reviewCount: number;
  loading: boolean;
  page: number;
  pageSize: number;
  onChangePage: (newPage: number) => void;
}

export const CommentList = ({
  page,
  pageSize,
  reviews,
  reviewCount,
  loading,
  onChangePage,
}: CommentListProps) => {
  const { t } = useTranslation('report');
  const { showGradient, scrollContainerRef } = useGradientScroll();

  return (
    <div ref={scrollContainerRef} className="flex h-[34.25rem] flex-col gap-4 overflow-y-auto p-4">
      <SkeletonLoader loading={loading} className="h-20">
        {reviews.length > 0 ? (
          reviews.map((review, index) => (
            <RatingComment
              key={`${review.date}-${index}`}
              averageRating={NumberUtils.toNumber(review.rating)}
              title={DateUtils.formatDate(review.date, DateUtils.FORMATS.P)}
              content={review.comment}
            />
          ))
        ) : (
          <p className="text-sm">{t('report.page.product.noReviews', { ns: 'report' })}</p>
        )}
        <TablePagination
          className="mt-auto"
          value={page}
          rowsPerPage={pageSize}
          count={reviewCount}
          onChange={onChangePage}
        />
      </SkeletonLoader>
      {showGradient && (
        <div className="bg-bottomGradient absolute bottom-0 left-0 right-0 h-24 border-0" />
      )}
    </div>
  );
};
