import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted, Auth0Permissions } from '~anyx/common/permission';
import { FALLBACK_ROUTE, ReportProductRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const ProductReportPage = React.lazy(() =>
  import('./pages').then((module) => ({ default: module.ProductReportPage }))
);

const ProductTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ProductTemplate }))
);

const ProductDetailedPage = React.lazy(() =>
  import('./pages/detailed').then((module) => ({ default: module.ProductDetailedPage }))
);

const ProductDetailedTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ProductDetailedTemplate }))
);

const ProductDetailedSalesPage = React.lazy(() =>
  import('./pages/detailed-sales').then((module) => ({
    default: module.ProductDetailedSalesPage,
  }))
);

const ProductDetailedReviewPage = React.lazy(() =>
  import('./pages/detailed-review').then((module) => ({
    default: module.ProductDetailedReviewPage,
  }))
);

export const routes: RouteObject[] = [
  {
    path: 'products',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_ANALYTICS]]}
          fallback={<UnauthorizedMessage />}
        >
          <ProductTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <ProductReportPage />,
      },
      {
        path: ':productId',
        element: <ProductDetailedTemplate />,
        children: [
          {
            index: true,
            element: <ProductDetailedPage />,
          },
          {
            path: 'sales',
            element: <ProductDetailedSalesPage />,
          },
          {
            path: 'review',
            element: <ProductDetailedReviewPage />,
          },
          { element: <Navigate to={FALLBACK_ROUTE} replace={true} /> },
        ],
      },
      {
        index: true,
        element: (
          <Navigate
            to={ReportProductRoutePath({ workspaceStoreId: null }).list().path}
            replace={true}
          />
        ),
      },
    ],
  },
];
